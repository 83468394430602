/*import "./src/libs/semantic-ui/semantic.less";
import "./src/styles/global.less";
*/

import ReactDOM from "react-dom";
import "./src/styles/opensans.less";
import "./src/styles/roboto.less";
import "./src/styles/animations.less";
import "./src/styles/global.less";

import {library} from "@fortawesome/fontawesome-svg-core";
import {faCheckSquare,faCoffee,faBullhorn,faGlobe,faBars,faQuoteLeft,faQuoteRight} from "@fortawesome/free-solid-svg-icons";
import {faTwitter,faFacebookF,faLinkedinIn,faInstagram} from "@fortawesome/free-brands-svg-icons";
library.add(faCheckSquare,faCoffee,faBullhorn,faGlobe,faTwitter,faFacebookF,faLinkedinIn,faInstagram,faBars,faQuoteLeft,faQuoteRight);

export const replaceHydrateFunction = () => (element, container, callback) =>
    ReactDOM.render(element, container, callback);
